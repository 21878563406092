import {ActionFunctionArgs, LinksFunction, redirect} from '@remix-run/node';
import {Form, Link, useActionData, useNavigation} from '@remix-run/react';
import {getRequestFormData} from 'conventional-form';
import {CircleSlash, Mail} from 'lucide-react';
import {DisplayAds} from '~/components/AdsItem';
import {Android} from '~/components/Android';
import {Apple} from '~/components/Apple';
import {EventList, groupAlertsByDate} from '~/components/EventList';
import {remixCaller} from '~/domain/api.server';
import {useRoot} from '~/domain/theme';
import {Alert, AlertTitle} from '~/ui/alert';
import {Button} from '~/ui/button';
import {Input} from '~/ui/input';
import {Label} from '~/ui/label';
import {ResponsiveDialog} from '~/ui/responsive-dialog';
import {Separator} from '~/ui/separator';
import {Spinner} from '~/ui/spinner';
import {BridgeStatus} from './bridge-status';
import {GoogleButton} from './google-button';

const imgUrl = 'https://lezo-files.s3.fr-par.scw.cloud/chaban-blog/chaban-opened.webp';

export const links: LinksFunction = () => [{rel: 'preload', as: 'image', href: imgUrl}];

export const action = async ({request}: ActionFunctionArgs) => {
  const caller = await remixCaller(request.headers);
  if (request.method === 'DELETE') {
    await caller.alert.makeReport({isRaised: true});
    return {};
  }
  if (request.method === 'PUT') {
    await caller.alert.makeReport({isRaised: false});
    return {};
  }
  if (request.method === 'POST') {
    const {url} = await caller.auth.requestGoogleOAuthUrl({mobile: false});
    return redirect(url);
  }

  const data = await getRequestFormData(request);
  await caller.auth.requestOtpByEmail(data);
  return {loginByEmail: true};
};

export default function Index() {
  const {alerts, isAuthed} = useRoot();
  const {laterAlerts, nextWeekAlerts, thisWeekAlerts, todayAlerts, tomorrowAlerts} = groupAlertsByDate(alerts);
  const actionData = useActionData<{loginByEmail?: boolean}>();
  const isLoginByEmailSent = actionData?.loginByEmail;
  const navigation = useNavigation();
  const isEmailSending = navigation.formMethod === 'PATCH';

  return (
    <div>
      <div className="relative aspect-16/9 w-full mb-8">
        <img
          src={imgUrl}
          alt="Pont Chaban Delmas ouvert"
          className="rounded-xl shadow-md w-full aspect-square sm:aspect-16/9 blur-sm contrast-50 brightness-50 object-cover"
        />
        <div className="absolute inset-0 flex flex-col justify-end items-center px-4">
          <h1 className="text-center font-black sm:text-6xl text-2xl mb-4 text-background/60">Mon Pont Chaban</h1>
          <h2 className="text-center font-bold sm:text-lg text-sm sm:mb-24 mb-4 text-background/80">
            Les horaires du pont Chaban-Delmas de Bordeaux en temps réel. Tenez vous informé des prochaines fermetures
            en un coup d'oeil.
          </h2>
          <div className="flex flex-row gap-4 w-full max-w-lg mb-4">
            <Button asChild>
              <Link to="https://apps.apple.com/app/mon-pont-chaban/id6448217836" className="flex-1">
                <span>iOS</span>
                <Apple className="h-4 w-4 mb-1 ml-2" />
              </Link>
            </Button>
            <Button asChild>
              <Link
                to="https://play.google.com/store/apps/details?id=com.simonboisset.monpontchaban"
                className="flex-1">
                <span>Android</span>
                <Android className="h-4 w-4 ml-2" />
              </Link>
            </Button>
          </div>
          {!isAuthed && (
            <>
              <Separator className="w-full max-w-sm" />
              <h5 className="text-center font-bold text-background/80">Se connecter</h5>
              <div className="flex flex-row w-full gap-4 max-w-lg items-center my-4">
                <ResponsiveDialog
                  trigger={
                    <Button className="flex-1">
                      <Mail className="size-5 mr-2" />
                      Par Mail
                    </Button>
                  }>
                  <Form method="PATCH" className="flex flex-col gap-4 m-4">
                    <Label>Email</Label>
                    <Input autoFocus type="email" name="email" required />
                    <Button type="submit">Envoyer {isEmailSending && <Spinner className="size-4 ml-2" />}</Button>
                    {isLoginByEmailSent && (
                      <Alert variant="success">
                        <AlertTitle>
                          Un email a été envoyé à l'adresse indiquée avec un lien pour vous connecter.
                        </AlertTitle>
                      </Alert>
                    )}
                  </Form>
                </ResponsiveDialog>
                <GoogleButton />
              </div>
            </>
          )}
        </div>
      </div>

      <BridgeStatus />

      <DisplayAds className="mt-12" />
      <EventList events={todayAlerts} title="Aujourd’hui" />
      <EventList events={tomorrowAlerts} title="Demain" />
      <EventList events={thisWeekAlerts} title="Cette semaine" />
      <EventList events={nextWeekAlerts} title="La semaine prochaine" />
      <EventList events={laterAlerts} title="Dans plus d'une semaine" />
      {!alerts?.length && (
        <div className="mt-12 flex flex-col gap-6 p-4 rounded-lg bg-foreground/10">
          <h3 className="font-bold text-2xl">Aucun évènement prévu pour le moment</h3>
          <p className="text-justify">
            Il n'y a actuellement aucun évènement prévu pour le pont Chaban Delmas. Lorsque les prochains seront
            annoncés par Bordeaux Métropole, ils apparaîtront ici.
          </p>
          <CircleSlash className="mx-auto w-32 h-32" />
        </div>
      )}
    </div>
  );
}
