import {Timer, fr} from '@chaban/core';
import {Form} from '@remix-run/react';
import {Check, OctagonX, TriangleAlert} from 'lucide-react';
import {toast} from 'sonner';
import {useRoot} from '~/domain/theme';
import {Badge} from '~/ui/badge';
import {Button} from '~/ui/button';
import {Separator} from '~/ui/separator';
import {cn} from '~/utils';
import {ClosedLogo} from '../../components/ClosedLogo';
import {OpenedLogo} from '../../components/OpenedLogo';

export const BridgeStatus = () => {
  const {currentStatus, alerts, isNotRaiseCount, isRaiseCount, isAuthed} = useRoot();
  const nextAlert = alerts[0];
  const isRaiseDiffCount = isRaiseCount - isNotRaiseCount;
  const onSubmitReport = () => {
    toast.success('Votre signalement a bien été pris en compte');
  };
  return (
    <section className="flex flex-col relative items-center bg-background/30 rounded-lg shadow-md p-8">
      <div className="flex flex-row gap-4 items-center w-full">
        {currentStatus === 'CLOSED' ? (
          <ClosedLogo className="size-16 sm:size-24" />
        ) : (
          <OpenedLogo className="size-16 sm:size-24" />
        )}

        <p className="sm:text-4xl text-2xl font-bold absolute top-8 h-16 sm:h-24 left-32 lg:right-32 right-8 flex flex-col md:flex-row justify-center items-center gap-4">
          <span>
            {currentStatus === 'CLOSED' ? fr.reopenIn : currentStatus === 'WILL_CLOSE' ? fr.closeIn : fr.opened}
          </span>
          {currentStatus === 'CLOSED' ? (
            <span>
              <Timer date={nextAlert.endAt} />
            </span>
          ) : (
            currentStatus === 'WILL_CLOSE' && (
              <span>
                <Timer date={nextAlert.startAt} />
              </span>
            )
          )}
        </p>
      </div>
      <Separator
        className={cn(
          'my-8 w-60',
          currentStatus === 'OPEN'
            ? 'bg-success-foreground/50'
            : currentStatus === 'WILL_CLOSE'
              ? 'bg-warning-foreground/50'
              : 'bg-error-foreground/50',
        )}
      />
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-col sm:flex-row gap-4 items-center">
          <TriangleAlert className="size-12 sm:size-8" />
          <span className="text-lg font-bold">Signalements en temps réel</span>
          {!isAuthed && <Badge variant="default">Connectez-vous pour faire un signalement</Badge>}
        </div>
        <p className="text-justify text-sm">
          Contribuez à la qualité des données en déclarant si le pont est levé ou baissé lorsque vous le traversez.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
          {currentStatus !== 'CLOSED' && isRaiseDiffCount > 0 && (
            <div className="bg-error/10 border border-error items-center rounded-full text-error-foreground px-2 flex flex-row">
              <ClosedLogo className="size-4 mr-2" />
              <span>
                {isRaiseCount} {isRaiseCount > 1 ? 'personnes ont' : 'personne a'} déclaré le pont fermé
              </span>
            </div>
          )}
          {currentStatus === 'CLOSED' && isRaiseDiffCount < 0 && (
            <div className="text-success-foreground flex flex-row items-center font-bold">
              <OpenedLogo className="size-6 mr-4" />
              <span>
                {isNotRaiseCount} {isNotRaiseCount > 1 ? 'personnes ont' : 'personne a'} déclaré le pont ouvert
              </span>
            </div>
          )}
          <div className="flex-1" />
          {isAuthed && (
            <>
              <Form method="DELETE" className="sm:w-auto w-full flex flex-row">
                <Button variant={'error'} className="flex-1" onClick={onSubmitReport}>
                  <OctagonX className="size-4 mr-2 " /> Le pont est fermé
                </Button>
              </Form>
              <Form method="PUT" className="sm:w-auto w-full flex flex-row">
                <Button variant={'success'} className="flex-1" onClick={onSubmitReport}>
                  <Check className="size-4 mr-2" /> Le pont est ouvert
                </Button>
              </Form>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
