import {useIsSmartphone} from '~/domain/gtm/use-media-query';
import {Dialog, DialogContent, DialogTrigger} from './dialog';
import {Drawer, DrawerContent, DrawerTrigger} from './drawer';

type ResponsiveDialogProps = {
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger: React.ReactNode;
};

export function ResponsiveDialog({children, open, onOpenChange, trigger}: ResponsiveDialogProps) {
  const isSmartphone = useIsSmartphone();

  if (isSmartphone) {
    return (
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerTrigger asChild>{trigger}</DrawerTrigger>
        <DrawerContent>{children}</DrawerContent>
      </Drawer>
    );
  }
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">{children}</DialogContent>
    </Dialog>
  );
}
