import {useEffect, useState} from 'react';
import {cn} from '~/utils';

export const AdsItem = ({className = ''}) => {
  useAds();

  return (
    <ins
      className={cn(className, 'adsbygoogle block w-full bg-white rounded-lg overflow-hidden shadow-sm')}
      data-ad-format="fluid"
      data-ad-layout-key="-g5+v-3t-bf+z7"
      data-ad-client="ca-pub-4102217073383903"
      data-ad-slot="1177271448"></ins>
  );
};

const useAds = () => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (!isReady) {
      setIsReady(true);
      return;
    }
    //@ts-expect-error
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    setIsReady(true);
  }, [isReady]);
  return isReady;
};

export const DisplayAds = ({className = ''}) => {
  return (
    <ins
      className={cn(className, 'adsbygoogle block bg-white rounded-lg overflow-hidden shadow-sm sm:mx-0 mx-4')}
      data-ad-client="ca-pub-4102217073383903"
      data-ad-slot="7890073353"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  );
};
