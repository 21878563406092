import {Alert} from '@chaban/api';
import {isNextWeek, isThisWeek, isToday, isTomorrow} from '@chaban/core';
import {cn} from '~/utils';
import {AdsItem} from './AdsItem';
import {BridgeEventItem} from './BridgeEventItem';

type EventListProps = {events: Alert[]; title: string};
export const EventList = ({events, title}: EventListProps) => {
  return events.length ? (
    <div className="flex flex-col gap-2 z-20 mt-16">
      <h2 className="text-2xl font-bold text-center mb-8">{title}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
        {events.map((data, i) => {
          const hasAds = i === 6 || (i + 1 > 7 && (i + 1) % 7 == 0);
          const isLeft = i % 2 === 0;
          return (
            <div key={data.id} className={cn(hasAds && 'grid-cols-1 sm:col-span-3 grid sm:grid-cols-3 gap-2')}>
              {isLeft && hasAds && <AdsItem className="sm:col-span-2" />}
              <BridgeEventItem {...data} />
              {!isLeft && hasAds && <AdsItem className="sm:col-span-2" />}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export const groupAlertsByDate = (alerts: Alert[]) => {
  const now = new Date();
  const todayAlerts = alerts.filter(({startAt}) => isToday(startAt, now));
  const tomorrowAlerts = alerts.filter(({startAt}) => isTomorrow(startAt, now));
  const thisWeekAlerts = alerts.filter(({startAt}) => isThisWeek(startAt, now));
  const nextWeekAlerts = alerts.filter(({startAt}) => isNextWeek(startAt, now));
  const laterAlerts = alerts.filter(
    ({startAt}) =>
      !isToday(startAt, now) && !isTomorrow(startAt, now) && !isThisWeek(startAt, now) && !isNextWeek(startAt, now),
  );
  return {
    todayAlerts,
    tomorrowAlerts,
    thisWeekAlerts,
    nextWeekAlerts,
    laterAlerts,
  };
};
